<template>
    <div>
        <p>Processing...</p>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';

export default {
    async created() {
        try {
            const { id } = this.$route.params;

            await this.loginAs(id);

            this.$router.push('/');
        } catch (error) {
            console.error(error);

            Sentry.captureException(error);

            this.$toasterError();
        }
    },

    methods: {
        ...mapActions({
            loginAs: 'auth/loginAs'
        })
    }
};
</script>
